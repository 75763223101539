import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const QuestionFieldWithBottomMargin = styled(QuestionField)`
  margin-bottom: ${spacing(6)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)}
    `}

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(7)};
  `}
`;

export const PostcodeQuestionField = styled(QuestionField)`
  ${Grid} > ${GridItem} {
    ${gridItemPropsOverride({ desktop: 3, tabletLandscape: 3, tabletPortrait: 3 })}
  }
`;
