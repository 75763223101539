import {
  lengthGreaterOrEqualTo,
  matchesRegex,
  required,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { POSTCODE_REGEX, REFERENCE_CODE_REGEX } from 'helpers/regexes';
import { RetrieveQuoteDetails } from 'state/formData/retrieveQuote';

type CsRetrieveLatestQuoteErrorMessages = {
  csPetRetrieveYourQuote: {
    quote_number_question: {
      error_messages: {
        missing: string;
        invalid_format: string;
      };
    };
  };
  csPetAboutYouManualAddress: {
    postcode: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetRetrieveYourQuote {
      quote_number_question {
        error_messages {
          missing
          invalid_format
        }
      }
    }
    csPetAboutYouManualAddress {
      postcode {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
    }
  }
`;

const useRetrieveLatestQuoteRules = (): ValidationRules<RetrieveQuoteDetails> => {
  const errorMessages = useStaticQuery<CsRetrieveLatestQuoteErrorMessages>(query);

  return {
    quoteNumber: [
      required(
        errorMessages.csPetRetrieveYourQuote.quote_number_question.error_messages.missing
      ),
      matchesRegex(
        REFERENCE_CODE_REGEX,
        errorMessages.csPetRetrieveYourQuote.quote_number_question.error_messages
          .invalid_format
      ),
    ],
    customerPostcode: [
      required(errorMessages.csPetAboutYouManualAddress.postcode.error_messages.missing),
      lengthGreaterOrEqualTo(
        5,
        errorMessages.csPetAboutYouManualAddress.postcode.error_messages.too_short
      ),
      matchesRegex(
        POSTCODE_REGEX,
        errorMessages.csPetAboutYouManualAddress.postcode.error_messages.invalid_format
      ),
    ],
  };
};

export default useRetrieveLatestQuoteRules;
